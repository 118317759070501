import React, { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// Providers
import { AppBridgeProvider, PolarisProvider, QueryProvider } from './theme/providers';
import { PopupProvider } from './contexts/PopupContext';
import commonService from './services/common.service';
import { TOKEN } from './constant/localstorage';

// ----------------------------------------------------------------------

export default function App() {

  useEffect(() => {
    fetchShopToken();
  });

  const fetchShopToken = async () => {
    const res = await commonService.getShopToken();
    if (res.status === 200) {
      console.log('res', res);
      localStorage.setItem(TOKEN, res.data);
    }
  }

  return (
    <PolarisProvider>
      <AppBridgeProvider>
        <QueryProvider>
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeSettings>
                <NotistackProvider>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <ScrollToTop />
                  <PopupProvider>
                    <Router />
                  </PopupProvider>
                </NotistackProvider>
              </ThemeSettings>
            </ThemeProvider>
          </MotionLazyContainer>
        </QueryProvider>
      </AppBridgeProvider>
    </PolarisProvider>
  );
}
