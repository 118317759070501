export const POPUP_SECTIONS = {
  COVER_IMAGE: 'COVER_IMAGE',
  TITLE: 'TITLE',
  SUBTITLE: 'SUBTITLE',
  PARAGRAPH: 'PARAGRAPH',
  BUTTON: 'BUTTON',
  INPUT: 'INPUT',
};

export const POPUP_TYPES = {
  WELCOME: 'WELCOME',
};
