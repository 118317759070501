import PropTypes from 'prop-types';
import { createContext, useState, useEffect, useRef } from 'react';
import { initialSchema } from 'src/constant/schema';
import popupService from 'src/services/popup.service';

// ----------------------------------------------------------------------

const initialState = {
  popupValues: initialSchema,
  setPopupValues: () => { },
};

const PopupContext = createContext(initialState);

// ----------------------------------------------------------------------

PopupProvider.propTypes = {
  children: PropTypes.node,
};

function PopupProvider({ children }) {
  const [popupValues, setPopupValues] = useState(initialSchema);

  // const init = useRef(false);

  // useEffect(() => {
  //   if (!init.current) {
  //     popupService
  //       .getPopupList()
  //       .then((res) => {
  //         console.log(res.data);
  //         setPopupValues(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     init.current = true;
  //   }
  // }, [init]);

  return <PopupContext.Provider value={{ popupValues, setPopupValues }}>{children}</PopupContext.Provider>;
}

export { PopupProvider, PopupContext };
