import axios from '../utils/axios';

const getShopToken = async () => {
    try {
        const res = await axios.post('/v1/token', { storeName: "https://yash-sonani.myshopify.com/" })
        return { status: res?.status, data: res?.data };
    } catch (err) {
        return { status: err?.response?.status };
    }
}

const commonService = {
    getShopToken,
};

export default commonService;
