import axios from 'axios';
import { TOKEN } from 'src/constant/localstorage';
// config
import { HOST_API } from '../config';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.authorization = localStorage.getItem(TOKEN);
  return config;
}, (error) => {
  Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
