import axios from '../utils/axios';

const getPopupList = async () => {
  try {
    const res = await axios.get('/popup').then(
      (response) => response,
      (error) => error
    );
    return { status: res?.status, data: res?.data?.data };
  } catch (err) {
    return { status: err?.response?.status };
  }
}

const createPopup = (data) =>
  axios.post('/popup', data).then(
    (response) => response,
    (error) => error
  );

const getPopupByID = async (id) => {
  try {
    const res = await axios.get(`/popup/${id}`);
    return { status: res?.status, data: res?.data?.data };
  } catch (err) {
    return { status: err?.response?.status };
  }
}

const popupService = {
  getPopupList,
  createPopup,
  getPopupByID
};

export default popupService;
