import { POPUP_SECTIONS, POPUP_TYPES } from './types';

export const initialSchema = {
  popUpType: POPUP_TYPES.WELCOME,
  popupSettings: {
    duration: '',
    position: '',
    customCSS: {
      borderWidth: '',
      borderStyle: '',
      borderColor: '',
      borderRadius: '',
      backgroundColor: '',
      backgroundImage: '',
    },
  },
  sections: [
    {
      type: POPUP_SECTIONS.COVER_IMAGE,
      src: '',
      setting: {
        customCSS: {
          width: '',
          height: '',
        },
      },
    },
    {
      type: POPUP_SECTIONS.TITLE,
      text: '',
      setting: {
        customCSS: {
          color: '',
          fontSize: '',
          fontFamily: '',
          fontStyle: '',
          textAlign: '',
          fontWeight: '',
        },
      },
    },
    {
      type: POPUP_SECTIONS.SUBTITLE,
      text: '',
      setting: {
        customCSS: {
          color: '',
          fontSize: '',
          fontFamily: '',
          fontStyle: '',
          textAlign: '',
          fontWeight: '',
        },
      },
    },
    {
      type: POPUP_SECTIONS.PARAGRAPH,
      text: '',
      setting: {
        customCSS: {
          color: '',
          fontSize: '',
          fontFamily: '',
          fontStyle: '',
          textAlign: '',
          fontWeight: '',
        },
      },
    },
    {
      type: POPUP_SECTIONS.BUTTON,
      text: '',
      variant: '',
      size: '',
      setting: {
        customCSS: {
          color: '',
          fontSize: '',
          fontFamily: '',
          fontStyle: '',
          textAlign: '',
          fontWeight: '',
        },
      },
    },
  ],
};
